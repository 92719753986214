<script>
import BasePlan from './base_plan.vue'
export default {
  extends: BasePlan,
  created() {
    this.isUpkeep = true
  },
  computed: {
    isActive() {
      return this.reservation.plan == 'upkeep'
    },
  }
}
</script>
