<template>
  <div class="btn-set btn-set2 mt-2 text-center" v-if="recurrence">
    <button type="button" class="btn btn-default active">{{recurrence}}</button>
    <p class="green-text font-14 font-mid-bold text-center" v-if="schedule">{{schedule}}</p>
  </div>
</template>
<script>
export default {
  props: {
    schedule: {
      type: String
    },
    recurrence: {
      type: String
    }
  },
}
</script>
