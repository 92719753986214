<template>
  <div class="clean-plan" :class="{ active: isActive }">
    <img  class="img-fluid" v-bind:src="imgSrc">
    <h3>{{title}}</h3>
    <div class="circul-box"><i v-if="isActive" class="fal fa-check"></i></div>
  </div>
</template>
<script>
import InitialImg from '@images/mprop-image01.png'
import UpkeepImg from '@images/mprop-image02.png'

export default {
  props: ['reservation'],
  data: function() {
    return {
      buttons: ['Weekly', 'Bi-weekly', 'Monthly'],
      isInitial: false, isUpkeep: false
    }
  },
  computed: {
    imgSrc() {
      return this.isInitial ? InitialImg : UpkeepImg
    },
    title() {
      return this.isInitial ? 'Standard(' + this.reservation.initial_price + ')' : 'Upkeep('+ this.reservation.upkeep_price + ')'
    },
  }
}
</script>
