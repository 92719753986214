<template>
  <div class="clean-plan-sec mt-0">
    <h2 class="sub-head text-center">Cleaning Plan</h2>
    <div class="row">
      <div class="col-6">
        <standard-plan :reservation="reservation"></standard-plan>
      </div>
      <div class="col-6">
        <upkeep-plan :reservation="reservation"></upkeep-plan>
      </div>
    </div>
    <schedule-info :reservation="reservation" class="text-center"></schedule-info>
  </div>
</template>
<script>
import StandardPlan from './standard_plan.vue'
import UpkeepPlan from './upkeep_plan.vue'
import ScheduleInfo from './schedule_info.vue'

export default {
  components: { StandardPlan, UpkeepPlan, ScheduleInfo },
  props: ['reservation'],
  data: function() {
    return {
      isInitial: false,
      isUpkeep: false
    }
  },
}
</script>
