<template>
  <div>
    <spinner :show="loading" :use-modal="false" message=''></spinner>

    <div v-if="reservations.length == 0 && !loading" class="d-flex justify-content-center align-items-center mt-4">
      <div>
        <h1 class="text-center main-head">
          <i class="fal fa-exclamation-circle"></i><br />
          No quotes created yet
        </h1>
      </div>
    </div>

    <div class="row" v-for="reservation in reservations">
      <div v-if="reservation.quote_only" class="col-lg-12 mt-4"><h5>{{requestedOn(reservation)}}</h5><hr></div>
      <div class="col-lg-6">
        <div class="box-wrapper same-height">
          <cleaning-plan :reservation="reservation"></cleaning-plan>
          <div v-if="!reservation.quote_only" class="col-lg-12 text-center"><h5>Starts at {{reservation.starts_at}}</h5></div>
          <div class="breakdown-sec pt-4">
            <h2 class="sub-head text-center mb-4">Cleaning Breakdown</h2>
            <div class="display-flex font-mid-bold mt-3" v-if="reservation.extras.length > 0">
              <div>Extra Charges</div>
              <div class="blue-text">{{reservation.total_extra_charges}}</div>
            </div>
            <div class="display-flex gray-text" v-for="extra in reservation.extras">
              <div>{{extra.name}} x {{extra.quantity}}</div>
              <div>{{extra.cost}}</div>
            </div>
            <hr>
            <div class="display-flex font-mid-bold">
              <div>Total Amount</div>
              <div class="blue-text">{{reservation.total_cleaning_charges}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="box-wrapper same-height">
          <div v-if="reservation.extras.length > 0">
            <h2 class="sub-head text-center">Selected Extras</h2>
            <div class="row mt-3 mb-3">
              <div class="col-md-3 col-sm-4 col-6" v-for="extra in reservation.extras">
                <div class="add-extras-list active">
                  <div class="icon">
                    <img :src="extra.icon_url">
                    <div class="check-circul-white" v-if="extra.quantity > 1">{{extra.quantity}}</div>
                  </div>
                  <h5>{{extra.name}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="property-detail-sec mt-0" :class="{ 'pt-5' : reservation.extras.length > 0 }">
            <h2 class="sub-head text-center mb-4">Property Details</h2>
            <div class="row">
              <div class="col-sm-6" v-for="row in reservation.details">
                <div class="row pl-3">
                  <p class="my-0">{{row.text}}&nbsp;
                    <span>
                      <label class="col-form-label blue-text">{{row.detail}}</label>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAdmin" class="col-12">
        <div class="mt-4 text-center">
          <a :href="bookFromQuoteLink(reservation.id)" class="btn btn-default btn-primary">BOOK THIS CLEANING</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@images/bflow-2-icon02.png'
import '@images/bflow-2-icon03.png'
import '@images/bflow-2-icon06.png'
import '@images/bflow-2-icon09.png'
import CleaningPlan from './cleaning_plan.vue'
import Spinner from '@/shared/spinner.vue'
export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  components: {
    CleaningPlan,
    Spinner
  },
  data() {
    return {
      loading: true,
      reservations: []
    }
  },
  methods: {
    bookFromQuoteLink(reservationId) {
      return '/quotes/' + reservationId + '/book'
    },
    loadData(reservationType) {
      var url = '/' + reservationType
      if (this.isAdmin) {
        url = '/admin/clients/' + this.userId + url
      }
      this.$http.get(url).then(response => {
        this.reservations = response.data
        this.loading = false
      }, response => {
        this.loading = false
        console.error(response.body)
        this.$flasher.error('Could not load the data')
      })
    },
    requestedOn(reservation) {
      return "Quote #" + reservation.id + " requested " + reservation.requested_on
    }
  }
}
</script>
